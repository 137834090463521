<div *ngIf="modules && categories">
    <div *ngFor="let module of modules" class="planning-module-blocks">

      <div class="wrap-collabsible">
        <input id="collapsible{{ module.id }}" class="toggle" type="checkbox" checked>
        <label for="collapsible{{ module.id }}" class="module-toggle">
          {{ module.name }}
          <app-published [published]="module.published"></app-published>
        </label>

        <div class="collapsible-content">
          <div class="content-inner">
            <app-module-instructions [module]="module"></app-module-instructions>
               <!-- {{ module | json}} -->
            <app-plan-activity *ngFor="let item of module.items" [moduleItem]="item" [categories]="categories" (changed)="itemPlanningChanged();"></app-plan-activity>
          </div>
        </div>
      </div>

    </div>

</div>
