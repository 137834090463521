<div *ngIf="!disabled" class="float-left" style="clear: left; padding: 0.25em 0.5em 0 0;">

  <ng-container *ngIf="moduleItem.activity_user && moduleItem.activity_user.imported < moduleItem.activity.updated; else not_updated">
    <div i18n-tooltip="@@AddToCalendar.Updated" tooltip="AddToCalendar.Updated {{ moduleItem.activity.updated | date:'d LLL yyyy' }}" placement="top" (mouseover)="changeImage=true" (mouseout)="changeImage=false">
      <img (click)="addToCalendar();" [src]="changeImage ? 'assets/images/cal_add.png' : 'assets/images/cal_updated.png'" alt="" />
    </div>
  </ng-container>

  <ng-template #not_updated>
    <ng-container *ngIf="moduleItem.activity_user && moduleItem.activity_user.imported; else not_imported">
      <div i18n-tooltip="@@AddToCalendar.Imported" tooltip="AddToCalendar.Imported {{ moduleItem.activity_user.imported | date:'d LLL yyyy' }}" placement="top" (mouseover)="changeImage=true" (mouseout)="changeImage=false">
        <img (click)="openModal(confirmReimport);" [src]="changeImage ? 'assets/images/cal_add.png' : 'assets/images/cal_imported.png'" alt="" />
      </div>
    </ng-container>

    <ng-template #not_imported>
      <div i18n-tooltip="@@App.Import" tooltip="App.Import" placement="top">
        <img (click)="addToCalendar();" src="assets/images/cal_add.png" alt="" />
      </div>
    </ng-template>
  </ng-template>

</div>


<ng-template #confirmReimport>
  <div class="modal-body text-center">
      <p i18n="@@AddToCalendar.ConfirmReimport">
          AddToCalendar.ConfirmReimport
      </p>
      <button type="button" class="btn btn-default" (click)="addToCalendar();" i18n="@@AddToCalendar.Add">AddToCalendar.Add</button>
      <button type="button" class="btn btn-primary" (click)="closeModal();" i18n="@@App.Cancel">App.Cancel</button>
  </div>
</ng-template>


<div style="display: none">
    <span #estimatedStudent i18n="@@AddToCalendar.EstimatedStudent">AddToCalendar.EstimatedStudent</span>
    <span #estimatedUnit i18n="@@App.EstimatedUnit">App.EstimatedUnit</span>
    <span #weeks i18n="@@App.Week">App.Week</span>
    <span #dates i18n="@@App.Dates">App.Dates</span>
    <span #numberOfDays i18n="@@AddToCalendar.NumberOfDays">AddToCalendar.NumberOfDays</span>
    <span #numberOfDaysUnit i18n="@@AddToCalendar.NumberOfDaysUnit">AddToCalendar.NumberOfDaysUnit</span>
    <span #estimatedTeacher i18n="@@AddToCalendar.EstimatedTeacher">AddToCalendar.EstimatedTeacher</span>
    <span #info i18n="@@AddToCalendar.Info">AddToCalendar.Info</span>
    <span #instructions i18n="@@AddToCalendar.Instructions">AddToCalendar.Instructions</span>
    <span #moduleInstruction i18n="@@AddToCalendar.ModuleInstruction">AddToCalendar.ModuleInstruction</span>
    <span #moduleItemCategories i18n="@@AddToCalendar.ModuleItemCategories">AddToCalendar.ModuleItemCategories</span>
</div>
