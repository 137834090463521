<h3><span i18n="@@StudentView.Heading">StudentView.Heading {{ courseCode }}</span></h3>

<div style="max-width: 1000px;">
  <p i18n="@@StudentView.Instructions">StudentView.Instructions</p>
</div>

<div *ngIf="modules && modules.length >= 1; then hasContent else noContent"></div>

<ng-template #hasContent>
  <div style="padding-left: 1.5em;">
    <div>
      <img src="assets/images/cal_add.png" style="width: 24px; height: 24px; margin-right: 0.5em;" alt="" />
      <span i18n="@@App.Import">App.Import</span>
    </div>
    <div>
      <img src="assets/images/cal_imported.png" style="width: 24px; height: 24px; margin-right: 0.5em;" alt="" />
      <span i18n="@@StudentView.ImportedInfo">StudentView.ImportedInfo</span>
    </div>
    <div>
      <img src="assets/images/cal_updated.png" style="width: 24px; height: 24px; margin-right: 0.5em;" alt="" />
      <span i18n="@@StudentView.UpdatedInfo">StudentView.UpdatedInfo</span>
    </div>
  </div>

  <ng-container *ngFor="let module of modules" class="planning-module-blocks">
    <div class="float-left wrap-collabsible module-display" style="margin-top: 0.5em !important; max-width: 1000px;">
      <input id="collapsible{{ module.id }}" class="toggle" type="checkbox" checked>
      <label for="collapsible{{ module.id }}" class="module-toggle">{{ module.name }}</label>
      <div class="collapsible-content">
        <div class="content-inner">
          <div class="float-left module-instruction" style="clear: left;">
            {{ module.instruction }}
          </div>
          <app-student-view-item *ngFor="let item of module.items | orderByDate" [module]="module" [moduleItem]="item"></app-student-view-item>
        </div>
      </div>
    </div>
  </ng-container>
</ng-template>

<ng-template #noContent>
    <div class="alert alert-warning planning-not-active" role="alert" i18n="@@StudentView.NoActivities">
      StudentView.NoActivities
    </div>
</ng-template>

