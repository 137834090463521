import { Component, OnInit, Input, ViewChild, TemplateRef  } from '@angular/core';

import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { BackendService } from 'src/app/services/backend.service';
import { Module } from 'src/app/models/Module';
import { ModuleItem } from 'src/app/models/ModuleItem';
import { CalendarEvent } from 'src/app/models/CalendarEvent';
import { NumberOfDaysPipe } from 'src/app/number-of-days.pipe';
import { StartStopPipe } from 'src/app/startstop.pipe';
import { WeeksPipe } from 'src/app/weeks.pipe';


@Component({
  selector: 'app-add-to-calendar',
  templateUrl: './add-to-calendar.component.html',
  styleUrls: ['./add-to-calendar.component.css']
})
export class AddToCalendarComponent implements OnInit {

  public changeImage: boolean;
  public modalRef: BsModalRef;

  @Input() module: Module;
  @Input() moduleItem: ModuleItem;
  @Input() disabled: boolean;

  @ViewChild('estimatedStudent') estimatedStudent: any;
  @ViewChild('estimatedUnit') estimatedUnit: any;
  @ViewChild('weeks') weeks: any;
  @ViewChild('dates') dates: any;
  @ViewChild('numberOfDays') numberOfDays: any;
  @ViewChild('numberOfDaysUnit') numberOfDaysUnit: any;
  @ViewChild('estimatedTeacher') estimatedTeacher: any;
  @ViewChild('info') info: any;
  @ViewChild('instructions') instructions: any;
  @ViewChild('moduleInstruction') moduleInstruction: any;
  @ViewChild('moduleItemCategories') moduleItemCategories: any;

  constructor(
    private backendService: BackendService,
    private weeksPipe: WeeksPipe,
    private numberOfDaysPipe: NumberOfDaysPipe,
    private startStopPipe: StartStopPipe,
    private modalService: BsModalService) {
      this.changeImage = false;
    }

  ngOnInit() { }

  getDescription(): string {

    // estimated student paragraph
    // If no estimation from student set same time as teachers estimate
    const estimatedStudent = this.moduleItem.activity_user.estimated
    ? this.moduleItem.activity_user.estimated
    : this.moduleItem.activity.estimated;

    const estimatedStudentString = estimatedStudent
    ? estimatedStudent.toString() + ' '
    : '-';

    const estimatedStudentParagraph = this.estimatedStudent.nativeElement.innerHTML
      + ': ' + estimatedStudentString
      + ' ' + this.estimatedUnit.nativeElement.innerHTML;

    // estimated teacher paragraph
    const estimatedTeacherString = this.moduleItem.activity.estimated
    ? this.moduleItem.activity.estimated.toString()
    : '-';

    const estimatedTeacherParagraph = this.estimatedTeacher.nativeElement.innerHTML
    + ': ' + estimatedTeacherString
    + ' ' + this.estimatedUnit.nativeElement.innerHTML;

    // weeks paragraph
    let weeksString = this.weeksPipe.transform(this.moduleItem.activity);
    if (weeksString === '') {
      weeksString = '-';
    }
    const weeksParagraph = this.weeks.nativeElement.innerHTML
      + ': '
      + weeksString;

    // dates paragrap
    let datesString = this.startStopPipe.transform(this.moduleItem.activity);
    if (datesString === '') {
      datesString = '-';
    }
    const datesParagraph = this.dates.nativeElement.innerHTML
      + ': '
      + datesString;

    // number of days paragraph
    let numberOfDaysString = this.numberOfDaysPipe.transform(this.moduleItem.activity);
    if (numberOfDaysString === '') {
      numberOfDaysString = '-';
    }
    const numberOfDaysParagraph = this.numberOfDays.nativeElement.innerHTML
      + ': ' + numberOfDaysString
      + ' ' + this.numberOfDaysUnit.nativeElement.innerHTML;

    // info paragraph
    const infoParagraph = this.info.nativeElement.innerHTML
    + ': ' + '<a href="' + this.moduleItem.url + '" target="_blank">' + this.moduleItem.title + '</a>';

    // instructions paragraph
    const instructionsParagraph = this.instructions.nativeElement.innerHTML;

    // module instruction paragraph
    const moduleInstructionString = this.module.instruction
    ? this.module.instruction
    : '-';

    const moduleInstructionParagraph = '<b>' + this.moduleInstruction.nativeElement.innerHTML + ':</b> ' + moduleInstructionString;

    // categories paragraph
    const categoriesString = this.moduleItem.activity.categories
    ? this.moduleItem.activity.categories.map(e => e.name).join(' | ')
    : '-';

    const categoriesParagraph = '<b>' + this.moduleItemCategories.nativeElement.innerHTML + ': </b>' + categoriesString;


    // create and return description
    return '<p>' + numberOfDaysParagraph + '</p>'
      + '<p>' + estimatedStudentParagraph + '</p>'
      + '<p>' + estimatedTeacherParagraph + '</p>'
      + '<p>' + weeksParagraph + '</p>'
      + '<p>' + datesParagraph  + '</p>'
      + '<p>' + infoParagraph + '</p>'
      + '<p><b>' + instructionsParagraph + '</b></p>'
      + '<p>' + moduleInstructionParagraph + '</p>'
      + '<p>' + categoriesParagraph + '</p>';
  }

  addToCalendar(): void {
    const event: CalendarEvent = {
      title: this.moduleItem.title,
      description: this.getDescription(),
      startdate: this.moduleItem.activity.startdate,
      stopdate: this.moduleItem.activity.stopdate
    };

    this.backendService.importToCalendar(this.moduleItem.id, event)
      .subscribe({
        next: (date) => {
          this.moduleItem.activity_user.imported = date;
        }
      })
      .add(() => {
        this.closeModal();
      });
  }

  openModal(template: TemplateRef<any>): void {
    this.modalRef = this.modalService.show(template, {class: 'modal-sm'});
  }

  closeModal(): void {
    if (this.modalRef) {
      this.modalRef.hide();
    }
  }
}
