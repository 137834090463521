import { Component, ElementRef, Inject } from '@angular/core';

import { BehaviorSubject, forkJoin } from 'rxjs';

import { BackendService } from './services/backend.service';
import { LaunchInfo } from './models/LaunchInfo';
import { Module } from './models/Module';
import { Planning } from './models/Planning';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'planera-mera-ui';
  launchInfo: LaunchInfo;
  modules: Module[];

  constructor(
    private backendService: BackendService,
    private elementRef: ElementRef,
    @Inject('KauLTISessionToken') sessionToken: BehaviorSubject<string>
    ) {

    // sessionToken is an attribute from index.html:app-root.
    sessionToken.next(this.elementRef.nativeElement.getAttribute('sessionToken'));

    this.backendService.getLaunchInfo()
      .subscribe({
        next: (launchInfo) => {
          this.launchInfo = launchInfo;
        }
    });

    this.backendService.getModules()
    .subscribe({
      next: (modules) => {
        this.modules = modules;
      }
    });
  }
}
