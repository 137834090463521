import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Message, MessageTypes, MessageCodes } from '../models/Message';

// MeddelandeID med globalt scope.
let messageServiceNextId = 0;

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  private subject = new Subject<any>();

  constructor() {
  }

  addInfo(code: MessageCodes) {
    const message = this.createMessage(code);
    message.messageType = MessageTypes.Info;

    this.subject.next(message);
  }

  addWarning(code: MessageCodes) {
    const message = this.createMessage(code);
    message.messageType = MessageTypes.Warn;

    this.subject.next(message);
  }

  addError(code: MessageCodes) {
    const message = this.createMessage(code);
    message.messageType = MessageTypes.Error;

    this.subject.next(message);
  }

  getMessage(): Observable<Message> {
    return this.subject.asObservable();
  }

  private createMessage(code: MessageCodes): Message {
    const message = new Message();
    message.id = messageServiceNextId++;
    message.creationTime = Date.now();
    message.code = code;

    return message;
  }

}
