import { Component, OnInit, OnDestroy, Input, ViewChild, TemplateRef } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';

import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { Module } from 'src/app/models/Module';
import { ModuleItem } from 'src/app/models/ModuleItem';
import { Planning } from 'src/app/models/Planning';
import { BackendService } from 'src/app/services/backend.service';
import { BusyService } from 'src/app/services/busy.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-teacher-view',
  templateUrl: './teacher-view.component.html',
  styleUrls: ['./teacher-view.component.css']
})
export class TeacherViewComponent implements OnInit, OnDestroy  {

  private _modules: Module[];
  private _isBusy: Subscription;

  @Input()
  set modules(modules: Module[]) {
    this._modules = modules;
    this.setPlannedActivities();
  }
  get modules(): Module[] {
    return this._modules;
  }

  @ViewChild('confirmActivate') confirmActivate: any;
  @ViewChild('confirmDeactivate') confirmDeactivate: any;

  public modalRef: BsModalRef;
  public planning: Planning;
  public planningForm: FormGroup;
  public plannedActivities: ModuleItem[];

  constructor(
    private backendService: BackendService,
    private modalService: BsModalService,
    private formBuilder: FormBuilder,
    private busyService: BusyService
  ) { }

  ngOnInit() {
    this.backendService.getPlanning()
      .subscribe({
        next: (planning) => {
          this.planning = planning;
          this.buildForm();

          // Set up listener to disable form when application is busy
          this._isBusy = this.busyService.isBusy
            .subscribe({
              next: (isBusy: boolean) => {
                isBusy ? this.planningForm.disable() : this.planningForm.enable();
              }
            });
        }
      });
  }

  ngOnDestroy() {
    this._isBusy.unsubscribe();
  }

  /**
   * Builds form for editing whether planning is active or not
   */
  buildForm(): void {
    this.planningForm = this.formBuilder.group({
      active: new FormControl(this.planning.activated ? true : false)});
  }

  /**
   * Sets array plannedActivities that serves as an overview (displayed in read-only table)
   * Module item is included in array when it
   *   is published (and part of published module)
   *   has a visible activity
   */
  setPlannedActivities(): void {
    if (!this.modules) {
      return;
    }

    const activities: ModuleItem[] = [];

    this.modules.forEach(module => {
      if (!module.published) {
        return;
      }

      if (!module.items) {
        return;
      }

      module.items.forEach(item => {
        if (!item.published) {
          return;
        }

        if (!item.activity || !item.activity.visible) {
          return;
        }

        // add module item to list of planned activities
        // (new object reference needed here for change detection to re-run pipes)
        activities.push(Object.assign({}, item));
      });
    });

    this.plannedActivities = activities;
  }

  /**
   * Handles changes to activities by recreating list of planned activities
   */
  activitiesChanged(): void {
    this.setPlannedActivities();
  }

  openModal(template: TemplateRef<any>): void {
    this.modalRef = this.modalService.show(template, {class: 'modal-sm'});
  }

  closeModal(): void {
    this.modalRef.hide();
  }

  /**
   * Activates or deactivates planning
   * @param active whether planning should be active or not
   */
  setPlanningActive(active: boolean): void {
    this.backendService.setPlanningActive(active)
      .subscribe({
        next: (activated) => {
          this.planning.activated = activated;
          this.buildForm();
        }
      })
      .add(() => {
        this.closeModal();
      });
  }

  /**
   * Handles changes in planning form by opening confirm modal
   */
  activeChanged(): void {

    // reset form until change is confirmed and successful
    const active = this.planningForm.value.active;
    this.buildForm();

    if (active === true) {
      this.openModal(this.confirmActivate);
    } else {
      this.openModal(this.confirmDeactivate);
    }
  }
}
