<app-messages></app-messages>
<ng-container *ngIf="launchInfo && modules;else loading">
    <ng-container *ngIf="launchInfo.is_instructor;else student">
        <app-teacher-view [modules]="modules"></app-teacher-view>
    </ng-container>
    <ng-template #student>
        <app-student-view [modules]="modules" [courseCode]="launchInfo.course_code"></app-student-view>
    </ng-template>
</ng-container>
<ng-template #loading><p i18n="@@App.Loading">App.Loading</p></ng-template>
