<div id="messages-overlay">
    <div *ngFor="let message of messages" [@simpleFadeAnimation]="'in'" class="alert" [class.alert-success]="message.messageType == messageTypes.Info" [class.alert-warning]="message.messageType == messageTypes.Warn" [class.alert-danger]="message.messageType == messageTypes.Error">
      {{ message.text }}
      <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="removeMessage(message);">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
  <div style="display: none">
    <span #msg id="ERR_GENERAL" i18n="@@Message.ERR_GENERAL">Message.ERR_GENERAL</span>
    <span #msg id="ERR_SAVE" i18n="@@Message.ERR_SAVE">Message.ERR_SAVE</span>
    <span #msg id="IMPORTED" i18n="@@Message.IMPORTED">Message.IMPORTED</span>
  </div>