import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';

import { Subscription } from 'rxjs';

import { BackendService } from 'src/app/services/backend.service';
import { BusyService } from 'src/app/services/busy.service';
import { Module } from 'src/app/models/Module';
import { ModuleItem } from 'src/app/models/ModuleItem';

let appStudentViewItemNextId = 0;

@Component({
  selector: 'app-student-view-item',
  templateUrl: './student-view-item.component.html',
  styleUrls: ['./student-view-item.component.css']
})
export class StudentViewItemComponent implements OnInit, OnDestroy {

  private _isBusy: Subscription;

  @Input() module: Module;
  @Input() moduleItem: ModuleItem;

  public id = 'app-student-view-item' + appStudentViewItemNextId++;
  public activityForm: FormGroup;
  public isBusy: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private backendService: BackendService,
    private busyService: BusyService) { }

  ngOnInit() {
    this.buildForm();

    // Set up listener to disable form when application is bus
    this._isBusy = this.busyService.isBusy
    .subscribe({
      next: (isBusy: boolean) => {
        isBusy ? this.activityForm.disable() : this.activityForm.enable();
        this.isBusy = isBusy;
      }
    });
  }

  ngOnDestroy() {
    this._isBusy.unsubscribe();
  }

  /**
   *  Builds form for editing activity planned for current user
   *
   * @param activity activity planned for current user
   */
  buildForm(): void {

    // if no estimate set by student default to estimate set by teacher
    let estimated = this.moduleItem.activity ? this.moduleItem.activity.estimated : null;
    if (this.moduleItem.activity_user && this.moduleItem.activity_user.estimated >= 0) {
      estimated = this.moduleItem.activity_user.estimated;
    }

    this.activityForm = this.formBuilder.group({
      estimated: new FormControl(estimated),
    });
  }

  /**
   * Updates estimated time for planned activity for current user
   */
  updateEstimated(): void {

    // only update if changed
    if (!this.activityForm.get('estimated').dirty) {
      return;
    }

    const estimated = this.activityForm.value.estimated;
    this.backendService.updateActivityUserEstimated(this.moduleItem.id, estimated)
      .subscribe({
        next: () => {
          this.moduleItem.activity_user.estimated = estimated;
          this.buildForm();
        }
      });
  }
}

