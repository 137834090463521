<div>
  <h3 i18n="@@TeacherView.Heading">TeacherView.Heading</h3>
  <div style="max-width: 1000px;">
    <p i18n="@@TeacherView.Instructions">TeacherView.Instructions</p>
    <ul i18n="@@TeacherView.InstructionList">
        <li>1</li>
        <li>2</li>
        <li>3</li>
        <li>4</li>
        <li>5</li>
        <li>6</li>
        <li>7</li>
    </ul>
  </div>

  <ng-container *ngIf="modules && plannedActivities">
    <div style="margin-right: 1em; margin: 1em 0 1em 1em;">
        <form *ngIf="planningForm" [formGroup]="planningForm">
            <div class="custom-control custom-switch">
                <input type="checkbox" class="custom-control-input" id="planning-active" formControlName="active" (change)="activeChanged();" />
                <label class="custom-control-label" for="planning-active" i18n="@@TeacherView.Activated">TeacherView.Activated</label>
            </div>
            <small>
                <ng-container i18n="@@TeacherView.ActivatedInstructions">TeacherView.ActivatedInstructions</ng-container>&nbsp;
                <ng-container *ngIf="planningForm.value.active === true" i18n="@@TeacherView.ActivatedDate">TeacherView.ActivatedDate {{ planning.activated | date: 'y-MM-dd'}}</ng-container>
            </small>
        </form>
    </div>
      <tabset>
          <tab i18n-heading="@@TeacherView.PlanActivities" heading="TeacherView.PlanActivities">
              <app-plan-activities [modules]="modules" (changed)="activitiesChanged();"></app-plan-activities>
          </tab>
          <tab i18n-heading="@@TeacherView.ViewPlanning" heading="TeacherView.ViewPlanning">
              <app-activities-table [activities]="plannedActivities | orderByDate"></app-activities-table>
          </tab>
      </tabset>
  </ng-container>
  <ng-template #confirmActivate>
      <div class="modal-body text-center">
          <p i18n="@@TeacherView.ConfirmActivate">
              TeacherView.ConfirmActivate
          </p>
          <button type="button" class="btn btn-default" (click)="setPlanningActive(true);" i18n="@@TeacherView.Activate">TeacherView.Activate</button>
          <button type="button" class="btn btn-primary" (click)="closeModal();" i18n="@@App.Cancel">App.Cancel</button>
      </div>
  </ng-template>
  <ng-template #confirmDeactivate>
      <div class="modal-body text-center">
          <p i18n="@@TeacherView.ConfirmDeactivate">
              TeacherView.ConfirmDeactivate
          </p>
          <button type="button" class="btn btn-default" (click)="setPlanningActive(false);" i18n="@@TeacherView.Deactivate">TeacherView.Deactivate</button>
          <button type="button" class="btn btn-primary" (click)="closeModal();" i18n="@@App.Cancel">App.Cancel</button>
      </div>
  </ng-template>

</div>
