import { Pipe, PipeTransform } from '@angular/core';
import { Activity } from './models/Activity';

@Pipe({
  name: 'numberOfDays'
})
export class NumberOfDaysPipe implements PipeTransform {

  transform(value: Activity): string {

    if (!value.stopdate) {

      if (value.startdate) {
        return '1';
      }
      return '';
    }

    const startDate = new Date(value.startdate);
    const stopDate = new Date(value.stopdate);

    // vi hanterar bara datum så tiden är satt till 00:00:00
    // sätt den så att hela dagen som aktiviteten slutar räknas med
    stopDate.setHours(23, 59, 59);

    const millisecondsPerDay = 24 * 60 * 60 * 1000;
    const dayDiff = (stopDate.getTime() - startDate.getTime()) / millisecondsPerDay;

    return Math.round(dayDiff).toString();
  }

}
