<div *ngIf="activities">
    <table class="table table-bordered">
        <tr>
            <th>
                <span i18n="@@App.Week">App.Week</span>
            </th>
            <th>
                <span i18n="@@App.Dates">App.Dates</span>
            </th>
            <th>
                <span i18n="@@App.Activity">App.Activity</span>
            </th>
            <th>
                <span i18n="@@App.Estimated">ActivitiesTable.EstimatedHeader</span>
            </th>
        </tr>
        <tr *ngFor="let item of activities | orderByDate">
            <td>
                {{ item.activity | weeks }}
            </td>
            <td>
                {{ item.activity | startstop }}
            </td>
            <td>
                <a href="{{ item.url }}" target="_blank">{{ item.title }}</a>
            </td>
            <td>
                <ng-container *ngIf="item.activity.estimated || item.activity.estimated === 0">
                    {{ item.activity.estimated }}&nbsp;<span i18n="@@App.EstimatedUnit">App.EstimatedUnit</span>
                </ng-container>
            </td>
        </tr>
    </table>
</div>

<!-- {{ activities | json }} -->
