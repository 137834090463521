import { Component, OnInit, OnDestroy, QueryList, ViewChildren, AfterViewInit } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { Subscription, interval } from 'rxjs';

import { MessageService } from '../../services/message.service';
import { Message, MessageTypes } from '../../models/Message';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.css'],
  animations: [
    // animation triggers go here

    trigger('simpleFadeAnimation', [

      state('in', style({opacity: 1})),

      transition(':enter', [
        style({opacity: 0}),
        animate('0.6s' )
      ]),

      transition(':leave',
        animate('0.3s', style({opacity: 0})))
    ])
  ]
})
export class MessagesComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChildren('msg') values: QueryList<any>;

  enrichedMessages: any = {};
  messageTypes = MessageTypes;
  messages: Message[] = [];
  subscription: Subscription;

  constructor(private messageService: MessageService) {
    // Periodvis rensning av meddelanden
    interval(2500)
      .subscribe(() => this.purgeOldMessages());
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    // Prenumerera på meddelanden från MessageService
    this.subscription = this.messageService.getMessage()
    .subscribe(message => { this.messages.push(this.enrich(message)); });

    // Bygg en mapp med meddelanden utifrån data i templaten, språkväxlade och klara.
    this.values.forEach(c => {
      this.enrichedMessages[c.nativeElement.id] = c.nativeElement.innerHTML;
    });
  }

  ngOnDestroy() {
    // Avsluta prenumerationen för att förhindra minnesläckage
    this.subscription.unsubscribe();
  }

  /**
   * Berika meddelande med text (om det finns)
   *
   * Om det finns färdiga meddelandetexter så byt ut befintliga mot dessa
   * @param message Message
   */
  enrich(message: Message): Message {
    message.text = this.enrichedMessages[message.code] || message.code;
    return message;
  }

  /**
   * Radera meddelande som användaren tryckt [X] på
   *
   * @param message Message
   */
  removeMessage(message: Message) {
    this.messages.forEach( (item, index) => {
      if (item.id === message.id) {
        this.messages.splice(index, 1);
      }
    });
  }

  /**
   * Radera meddelanden som är äldre än X
   */
  private purgeOldMessages() {
    const now = Date.now();
    let stillSearching = true; // Begränsa till 1st raderat meddelande per anrop
    this.messages.forEach( (item, index) => {
      if (stillSearching && now - item.creationTime >= 5000) {
        this.messages.splice(index, 1);
        stillSearching = false;
      }
    });
  }

}
