import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { BusyService } from '../services/busy.service'

@Injectable()
export class BusyInterceptor implements HttpInterceptor {

  private requests: HttpRequest<any>[] = [];

  constructor(private busyService: BusyService) {}

  removeRequest(request: HttpRequest<any>) {
    const i = this.requests.indexOf(request);
    if (i >= 0) {
      this.requests.splice(i, 1);
    }
    this.busyService.isBusy.next(this.requests.length > 0);
  }


  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.requests.push(request);
    this.busyService.isBusy.next(true);
    // We create a new observable which we return instead of the original
    return new Observable((observer) => {
      // And subscribe to the original observable to ensure the HttpRequest is made
      const subscription = next.handle(request)
        .subscribe(
        event => {
          if (event instanceof HttpResponse) {
            this.removeRequest(request);
            observer.next(event);
          }
        },
        err => { this.removeRequest(request); observer.error(err); },
        () => { this.removeRequest(request); observer.complete(); });
      // return teardown logic in case of cancelled requests
      return () => {
        this.removeRequest(request);
        subscription.unsubscribe();
      };
    });
  }
 }
