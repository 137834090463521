export class Message {
    id: number;
    code: MessageCodes;
    text?: string;
    creationTime: number;
    messageType: MessageTypes;
}

export enum MessageTypes {
  Info,
  Warn,
  Error,
}

export enum MessageCodes {
  errGeneral  = 'ERR_GENERAL',
  errSave = 'ERR_SAVE',
  imported = 'IMPORTED'
}
