import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

import { Activity } from './models/Activity';

@Pipe({
  name: 'startstop'
})
export class StartStopPipe implements PipeTransform {

  constructor(private datePipe: DatePipe) { }

  transform(value: Activity): string {

    const startdateString = this.datePipe.transform(value.startdate, 'd LLL yyyy');
    const stopdateString = this.datePipe.transform(value.stopdate, 'd LLL yyyy');

    if (!value.stopdate) {

      if (value.startdate) {
        return startdateString;
      }

      return '';
    }

    return startdateString + ' - ' + stopdateString;
  }
}
