import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { BackendService } from 'src/app/services/backend.service';
import { Module } from 'src/app/models/Module';
import { Category } from 'src/app/models/Category';

@Component({
  selector: 'app-plan-activities',
  templateUrl: './plan-activities.component.html',
  styleUrls: ['./plan-activities.component.css']
})
export class PlanActivitiesComponent implements OnInit {

  @Input() modules: Module[];

  @Output() changed: EventEmitter<any> = new EventEmitter<any>();

  public categories: Category[];

  constructor(private backendService: BackendService) { }

  ngOnInit() {
    this.backendService.getCategories()
      .subscribe({
        next: (categories) => {
          this.categories = categories;
        }
      });
  }

  itemPlanningChanged() {
    this.changed.emit();
  }
}
