import { Component, OnInit, Input } from '@angular/core';
import { Module } from 'src/app/models/Module';

@Component({
  selector: 'app-student-view',
  templateUrl: './student-view.component.html',
  styleUrls: ['./student-view.component.css']
})
export class StudentViewComponent implements OnInit {

  @Input() modules: Module[];
  @Input() courseCode: string;

  constructor() { }

  ngOnInit() {
  }

}
