import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DatePipe } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { BehaviorSubject } from 'rxjs';

import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { CollapseModule } from 'ngx-bootstrap/collapse';

import { AppComponent } from './app.component';
import { PlanActivitiesComponent } from './components/plan-activities/plan-activities.component';
import { PlanActivityComponent } from './components/plan-activity/plan-activity.component';
import { ActivitiesTableComponent } from './components/activities-table/activities-table.component';
import { TeacherViewComponent } from './components/teacher-view/teacher-view.component';
import { StudentViewComponent } from './components/student-view/student-view.component';
import { PublishedComponent } from './components/published/published.component';
import { MessagesComponent } from './components/messages/messages.component';
import { AddToCalendarComponent } from './components/add-to-calendar/add-to-calendar.component';
import { StudentViewItemComponent } from './components/student-view-item/student-view-item.component';
import { ModuleInstructionsComponent } from './components/module-instructions/module-instructions.component';

import { WeeksPipe } from './weeks.pipe';
import { NumberOfDaysPipe } from './number-of-days.pipe';
import { OrderByDatePipe } from './order-by-date.pipe';
import { StartStopPipe } from './startstop.pipe';

import { BusyInterceptor } from './interceptors/busy.interceptor';


@NgModule({
  declarations: [
    AppComponent,
    PlanActivitiesComponent,
    PlanActivityComponent,
    ActivitiesTableComponent,
    TeacherViewComponent,
    StudentViewComponent,
    PublishedComponent,
    MessagesComponent,
    AddToCalendarComponent,
    StudentViewItemComponent,
    ModuleInstructionsComponent,
    WeeksPipe,
    NumberOfDaysPipe,
    OrderByDatePipe,
    StartStopPipe
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    ReactiveFormsModule,
    BsDatepickerModule.forRoot(),
    BrowserAnimationsModule,
    TabsModule.forRoot(),
    ModalModule.forRoot(),
    TooltipModule.forRoot(),
    CollapseModule.forRoot(),
  ],
  providers: [
    { provide: 'KauLTISessionToken', useValue: new BehaviorSubject('') },
    { provide: HTTP_INTERCEPTORS, useClass: BusyInterceptor, multi: true },
    DatePipe,
    WeeksPipe,
    NumberOfDaysPipe,
    StartStopPipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
