import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Activity } from './models/Activity';

@Pipe({
  name: 'weeks'
})
export class WeeksPipe implements PipeTransform {

  constructor(private datePipe: DatePipe) { }

  transform(value: Activity): string {

    // TODO: angulars datepipe returnerar inte samma veckonummer vid årskifte som min outlook-kalender gör
    // så jag gissar att vi behöver komma på ett annat sätt att ta fram korrekt veckonummer
    const startweek  = value.startdate
    ? Number(this.datePipe.transform(value.startdate, 'w'))
    : null;

    const stopweek = value.stopdate
    ? Number(this.datePipe.transform(value.stopdate, 'w'))
    : null;

    if (stopweek === null) {
      if (startweek !== null) {
        return startweek.toString();
      }
      return '';
    }

    return (startweek === stopweek)
    ? startweek.toString()
    : startweek + '-' + stopweek;
  }
}
