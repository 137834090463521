import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { LaunchInfo } from '../models/LaunchInfo';
import { Module } from '../models/Module';
import { ModuleItem } from '../models/ModuleItem';
import { Activity } from '../models/Activity';
import { ActivityUser } from '../models/ActivityUser';
import { MessageService } from './message.service';
import { MessageCodes } from '../models/Message';
import { CalendarEvent } from '../models/CalendarEvent';
import { Planning } from '../models/Planning';
import { Category } from '../models/Category';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable({
  providedIn: 'root'
})
export class BackendService {

  constructor(
    private http: HttpClient,
    private messageService: MessageService,
    @Inject('KauLTISessionToken') sessionToken: BehaviorSubject<string>
  ) {
    sessionToken.subscribe((token) => httpOptions.headers = httpOptions.headers.set('Authorization', 'KauLTI ' + token));
  }

  /**
   * Gets categories available for planned activities
   * @returns an array of categories
   */
  getCategories(): Observable<Category[]> {
    const url = environment.apiUrl + '/categories';

    return this.http.get<Category[]>(url, httpOptions).pipe(
      catchError(this.handleError(MessageCodes.errGeneral))
    );
  }


 /**
  * Gets info about current launch (current course, user and role of user in course)
  * @returns an object containing the info
  */
  getLaunchInfo(): Observable<LaunchInfo> {
    const url = environment.apiUrl + '/ltiinfo';
    return this.http.get<LaunchInfo>(url, httpOptions);
  }

  /**
   * Gets all module of current course
   * @returns an array of modules
   */
  getModules(): Observable<Module[]> {
    const url = environment.apiUrl + '/modules';
    return this.http.get<Module[]>(url, httpOptions).pipe(
      catchError(this.handleError(MessageCodes.errGeneral))
    );
  }

  /**
   * Updates visibility of activity planned for a given module item
   * @param moduleItem module item
   * @param visible if activity is visible or not
   */
  updateActivityVisible(moduleItem: ModuleItem, visible: boolean): Observable<any> {
    const url = environment.apiUrl + '/moduleitem/' + moduleItem.id + '/activity/visible';

    return this.http.put<boolean>(url, visible, httpOptions).pipe(
      catchError(this.handleError(MessageCodes.errSave))
    );
  }

  /**
   * Updates start- and stopdate for activity planned for a given module item
   * @param moduleItem module item
   * @param startdate startdate
   * @param stopdate stopdate
   */
  updateActivityStartStop(moduleItem: ModuleItem, startdate: string, stopdate: string): Observable<any> {
    const url = environment.apiUrl + '/moduleitem/' + moduleItem.id + '/activity/startstop';
    const body = { start: startdate, stop: stopdate };

    return this.http.put<object>(url, body, httpOptions).pipe(
      catchError(this.handleError(MessageCodes.errSave))
    );
  }

  /**
   * Updates estimated time for activity planned for a given module item
   * @param moduleItem module item
   * @param estimated estimated time
   */
  updateActivityEstimated(moduleItem: ModuleItem, estimated: number): Observable<any> {
    const url = environment.apiUrl + '/moduleitem/' + moduleItem.id + '/activity/estimated';
    const body = {
      value: estimated
    };

    return this.http.put<object>(url, body, httpOptions).pipe(
      catchError(this.handleError(MessageCodes.errSave))
    );
  }

  /**
   * Adds category to a given module item
   * @param moduleItem module item
   * @param category category to add
   */
  addCategory(moduleItem: ModuleItem, category: Category): Observable<any> {
    const url = environment.apiUrl + '/moduleitem/' + moduleItem.id + '/activity/categories';

    return this.http.post<Category>(url, category, httpOptions).pipe(
      catchError(this.handleError(MessageCodes.errSave))
    );
  }

  /**
   * Removes category from a given module item
   * @param moduleItem module item
   * @param category category to remove
   */
  removeCategory(moduleItem: ModuleItem, category: Category): Observable<any> {
    const url = environment.apiUrl + '/moduleitem/' + moduleItem.id + '/activity/category/' + category.id;

    return this.http.delete<object>(url, httpOptions).pipe(
      catchError(this.handleError(MessageCodes.errSave))
    );
  }

  /**
   * Updates activity planned for a given module item by current user
   * @param moduleItemId id of module item
   * @param updatedActivity contains the values to set
   * @returns the updated activity returned from the api
   */
  updateActivityUserEstimated(moduleItemId: number, estimated: number): Observable<any> {
    const url = environment.apiUrl + '/moduleitem/' + moduleItemId + '/activityUser/estimated';
    const body = {
      value: estimated
    };

    return this.http.put<object>(url, body, httpOptions).pipe(
      catchError(this.handleError(MessageCodes.errSave))
    );
  }

  /**
   * Imports event for a given module item to calendar of current user
   * @param moduleItemId id of module item
   * @param event the event to import
   * @returns string containing date of import returned from the api
   */
  importToCalendar(moduleItemId: number, event: CalendarEvent): Observable<string> {

    const url = environment.apiUrl + '/moduleitem/' + moduleItemId + '/calendarEvent';

    // TODO: kanske bör ha ett mer specifikt felmeddelande här?
    return this.http.post<string>(url, event, httpOptions).pipe(
      tap(_ => this.messageService.addInfo(MessageCodes.imported)),
      catchError(this.handleError(MessageCodes.errGeneral))
    );
  }

  /**
   * Gets planning for current course
   * @returns planning
   */
  getPlanning(): Observable<Planning> {
    const url = environment.apiUrl + '/planning';

    return this.http.get<Planning>(url, httpOptions).pipe(
      catchError(this.handleError(MessageCodes.errGeneral))
    );
  }


  /**
   * Updates if planning for current course is active or not
   * @param active true or false
   * @returns string containing date of activation returned from the api
   */
  setPlanningActive(active: boolean): Observable<string> {
    const url = environment.apiUrl + '/planning/active';

    return this.http.put<string>(url, active, httpOptions).pipe(
      catchError(this.handleError(MessageCodes.errSave))
    );
  }

  /**
   * Updates instruction set for module
   * @param moduleId id of the module
   * @param instruction instruction to set
   * @returns updated instruction
   */
  updateModuleInstruction(moduleId: number, instruction: string): Observable<string> {
    const url = environment.apiUrl + '/module/' + moduleId + '/instruction';
    const body = {
      value: instruction
    };

    return this.http.put<string>(url, body, httpOptions).pipe(
      catchError(this.handleError(MessageCodes.errSave))
    );
  }

  /**
   * Handles error by adding message with specified code to MessageService
   * @param code MessageCode
   * @returns error for caller to handle
   */
  private handleError(code: MessageCodes) {
    return (error: any) => {
        // console.error(error);
        this.messageService.addError(code);
        return throwError(error);
    };
  }
}
