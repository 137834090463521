<div class="float-left planning-module" style="clear: left;">

  <div class="float-left">
    <div class="float-left" style="padding-left: 1em; font-weight: bold;">
      <span i18n="@@App.Activity">App.Activity</span>:
    </div>
    <div class="float-left" style="padding-left: 0.5em;">
      <a href="{{ moduleItem.url }}" target="_blank">{{ moduleItem.title }}</a>
    </div>

    <div class="float-left" style="clear: left; padding-left: 1em; font-weight: bold;">
      <span i18n="@@App.Week">App.Week</span>:
    </div>
    <ng-container *ngIf="moduleItem.activity.startdate; else no_startdate">
      <div class="float-left" style="padding-left: 0.5em;">
        {{ moduleItem.activity | weeks }}
        <i>({{ moduleItem.activity | startstop }})</i>
      </div>
    </ng-container>
    <ng-template #no_startdate>
      <div class="float-left" style="padding-left: 0.5em;">
        -
      </div>
    </ng-template>

    <div class="float-left" style="clear: left; padding-left: 1em; font-weight: bold;">
      <span i18n="@@App.Estimated">App.Estimated</span>:
    </div>
    <div class="float-left" style="padding-left: 0.5em;">
      {{ moduleItem.activity.estimated
            ? moduleItem.activity.estimated
            : '-'
      }}&nbsp;<span i18n="@@App.EstimatedUnit">App.EstimatedUnit</span>
    </div>

    <div class="float-left" style="clear: left; padding-left: 1em; font-weight: bold;">
      <span i18n="@@StudentViewItem.Estimated">StudentViewItem.Estimated</span>
    </div>
    <div class="float-left" style="margin-left: 0.5em; margin-top: -0.25em">
      <form *ngIf="activityForm" [formGroup]="activityForm">
        <input type="number" min="0" step="0.5" class="form-control" formControlName="estimated" (blur)="updateEstimated();" style="height: 1.8em; width: 125px; text-align: center;" />
      </form>
    </div>
  </div>

  <div class="float-right" style="padding: 1em;">
    <div *ngIf="moduleItem.activity.startdate" class="float-left">
      <app-add-to-calendar [module]="module" [moduleItem]="moduleItem" [disabled]="isBusy"></app-add-to-calendar>
    </div>
  </div>

  <div class="float-left" style="clear: left;">
    <div style="margin-top: 0.5em;">
      <span style="margin: 0.25em 0.25em 0 0; white-space: nowrap;" *ngFor="let category of moduleItem.activity.categories" class="float-left badge-pill badge-info">
        <i>{{ category.name }}</i>
      </span>
    </div>
  </div>

</div>
