<form *ngIf="activityForm" [formGroup]="activityForm" class="planning-module">
  <div class="row" style="padding: 0.5em 0.5em 0.5em 1em;">

    <div style="width: 35%;">
      <i class="fa fa-chevron-right" aria-hidden="true" style="font-size: 0.75em; color: #c0c0c0;"></i>
      <ng-container *ngFor="let x of ' '.repeat(moduleItem.indent).split('')"><i class="fa fa-chevron-right" aria-hidden="true" style="font-size: 0.75em; color: #c0c0c0;"></i></ng-container>
      <ng-container *ngIf="moduleItem.type !== 'SubHeader'; else subHeaderItem">
        <a class="module-item-title" href="{{ moduleItem.url }}" target="_blank">{{ moduleItem.title}}</a>

        <div class="module-item-toggle custom-control custom-switch">
          <input type="checkbox" class="custom-control-input" id="{{id}}-visible" formControlName="visible" (change)="updateVisible();" />
          <label class="custom-control-label" for="{{id}}-visible" i18n="@@PlanActivity.Visible">PlanActivity.Visible</label>
        </div>
      </ng-container>
      <ng-template #subHeaderItem>
        <label class="module-item-title subheader-item">{{ moduleItem.title }}</label><small>(<span i18n="@@PlanActivity.NotVisibleToStudents">PlanActivity.NotVisibleToStudents</span>)</small>
      </ng-template>
    </div>

    <ng-container *ngIf="moduleItem.type !== 'SubHeader'">
      <div style="width: 18%;">
        <button type="button" class="btn btn-primary" style="font-size: 0.8rem !important; white-space: nowrap;" (click)="categoriesCollapsed = !categoriesCollapsed"
          [attr.aria-expanded]="!categoriesCollapsed" aria-controls="categories">
          <ng-container *ngIf="categoriesCollapsed;else categoriesOpen" i18n="@@PlanActivity.CategoriesCollapsed">PlanActivity.CategoriesCollapsed"</ng-container>
          <ng-template #categoriesOpen i18n="@@PlanActivity.CategoriesOpen">PlanActivity.CategoriesOpen"</ng-template>
        </button>
      </div>

      <div style="width: 25%;">
        <div class="float-left" i18n-tooltip="@@PlanActivity.StartStop" tooltip="PlanActivity.StartStop">
          <i class="fa fa-calendar planning-fa-settings" aria-hidden="true" (click)="dp.show()"></i>
        </div>
        <div class="float-left">
          <input type="text" placeholder="PlanActivity.StartStopPlaceholder" i18n-placeholder="@@PlanActivity.StartStopPlaceholder" class="form-control planning-input-dates"
              #dp="bsDaterangepicker" bsDaterangepicker [bsConfig]="{ rangeInputFormat: 'YYYY-MM-DD' }" formControlName="startstop"
              (onHidden)="updateStartStop();" />
        </div>
        <div class="float-left">
            <div (click)="removeStartStop();">
                &nbsp;<i class="fa fa-times-circle planning-fa-settings" aria-hidden="true"></i>
            </div>
        </div>
      </div>

      <div style="width: 20%;">
        <div class="float-left" i18n-tooltip="@@PlanActivity.Estimated" tooltip="PlanActivity.Estimated">
          <label for="{{id}}-estimated">
            <i class="fa fa-clock-o planning-fa-settings" aria-hidden="true"></i>
          </label>
        </div>
        <div class="float-left">
          <input id="{{id}}-estimated" type="number" min="0" step="0.5" placeholder="App.Estimated" i18n-placeholder="@@App.Estimated" class="form-control planning-input-time"
              formControlName="estimated" (blur)="updateEstimated();" />
        </div>
        <div class="float-left planning-positioning-fix">
          &nbsp;<span i18n="@@App.EstimatedUnit">App.EstimatedUnit</span>
        </div>
        <div class="float-left planning-positioning-fix">
          <div (click)="removeEstimated();">
            &nbsp;<i class="fa fa-times-circle" aria-hidden="true" style="color: #777;"></i>
          </div>
        </div>
      </div>

      <div style="width: 2%;" class="planning-positioning-fix">
          <app-published [published]="moduleItem.published"></app-published>
      </div>

      <div class="planning-module-item" style="display: block; width: 100%;">
        <ng-container *ngFor="let category of moduleItem.activity.categories; last as isLast">
          <div class="float-left">
            <span style="white-space: nowrap;" class="badge-pill badge-info"><i>{{ category.name }}</i></span>
            <ng-container *ngIf="!isLast">&nbsp;</ng-container>
          </div>
        </ng-container>
      </div>

      <div style="display: inline-block; overflow: hidden;" id="categories" [collapse]="categoriesCollapsed" formArrayName="categories" *ngFor="let category of activityForm.get('categories')['controls']; let i = index">
        <div class="float-left" style="margin: 1em 0.25em 0 0.5em;">
          <input type="checkbox" id="{{id}}-category-{{i}}" [formControlName]="i" (change)="setCategory(i);">&nbsp;
          <label for="{{id}}-category-{{i}}">{{ categories[i].name }}</label>
        </div>
      </div>
    </ng-container>
  </div>
</form>
<!-- {{ planningForm.value | json}} -->
