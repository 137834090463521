import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';

import { Module } from 'src/app/models/Module';
import { BackendService } from 'src/app/services/backend.service';
import { BusyService } from 'src/app/services/busy.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-module-instructions',
  templateUrl: './module-instructions.component.html',
  styleUrls: ['./module-instructions.component.css']
})
export class ModuleInstructionsComponent implements OnInit, OnDestroy {
  private _isBusy: Subscription;

  @Input() module: Module;

  public instructionForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private backendService: BackendService,
    private busyService: BusyService) { }

  ngOnInit() {
    this.buildForm();

    // set up listener to disable form when application is busy
    this._isBusy = this.busyService.isBusy
      .subscribe({
        next: (isBusy: boolean) => {
          isBusy ? this.instructionForm.disable() : this.instructionForm.enable();
        }
      });
  }

  ngOnDestroy() {
    this._isBusy.unsubscribe();
  }

  /**
   * Updates instruction set for module
   */
  public updateInstruction(): void {

    // only update if changed
    if (!this.instructionForm.get('instruction').dirty) {
      return;
    }

    this.backendService.updateModuleInstruction(this.module.id, this.instructionForm.value.instruction)
      .subscribe({
        next: (instruction) => {
          this.module.instruction = instruction;
          this.buildForm();
        }
      });
  }

  /**
   * Builds a form for editing module instructions
   */
  private buildForm(): void {
    this.instructionForm = this.formBuilder.group({
      instruction: new FormControl(this.module.instruction),
    });
  }

}
