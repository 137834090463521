import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BusyService {

    public isBusy = new BehaviorSubject(false);
    constructor() {}
}
