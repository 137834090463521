import { Pipe, PipeTransform } from '@angular/core';
import { ModuleItem } from 'src/app/models/ModuleItem';

@Pipe({
  name: 'orderByDate'
})
export class OrderByDatePipe implements PipeTransform {

  compareModuleItems(item1: ModuleItem, item2: ModuleItem): number {

    // sortera items utan datumuppgift sist
    if (!item1.activity.startdate  && !item2.activity.startdate) {
      return 0;
    }

    if (!item1.activity.startdate) {
      return 1;
    }

    if (!item2.activity.startdate) {
      return -1;
    }

    // sortera på startdatum
    if (item1.activity.startdate < item2.activity.startdate) {
      return -1;
    }

    if (item1.activity.startdate > item2.activity.startdate) {
      return 1;
    }
    // sortera de som har samma startdatum på slutdatum
    if (item1.activity.stopdate < item2.activity.stopdate) {
      return -1;
    }

    if (item1.activity.stopdate > item2.activity.stopdate) {
      return 1;
    }

    return 0;
  }

  transform(value: ModuleItem[]): ModuleItem[] {
    return value.sort(this.compareModuleItems);
  }

}
