import { Component, OnInit, Input } from '@angular/core';

import { ModuleItem } from 'src/app/models/ModuleItem';

@Component({
  selector: 'app-activities-table',
  templateUrl: './activities-table.component.html',
  styleUrls: ['./activities-table.component.css']
})
export class ActivitiesTableComponent implements OnInit {

  @Input() activities: ModuleItem[];

  constructor() { }

  ngOnInit() {
  }

}
