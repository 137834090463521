import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, FormArray } from '@angular/forms';

import { Subscription } from 'rxjs';

import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { svLocale } from 'ngx-bootstrap/locale';
import { defineLocale } from 'ngx-bootstrap/chronos';

import { BackendService } from 'src/app/services/backend.service';

import { ModuleItem } from 'src/app/models/ModuleItem';
import { Category } from 'src/app/models/Category';
import { BusyService } from 'src/app/services/busy.service';

let appPlanActivityNextId = 0;

@Component({
  selector: 'app-plan-activity',
  templateUrl: './plan-activity.component.html',
  styleUrls: ['./plan-activity.component.css']
})
export class PlanActivityComponent implements OnInit, OnDestroy {
  private _isBusy: Subscription;

  @Input() moduleItem: ModuleItem;
  @Input() categories: Category[];

  @Output() changed: EventEmitter<ModuleItem> = new EventEmitter<ModuleItem>();

  public id = 'app-plan-activity-' + appPlanActivityNextId++;
  public activityForm: FormGroup;
  public categoriesCollapsed = true;

  constructor(
    private formBuilder: FormBuilder,
    private backendService: BackendService,
    private localeService: BsLocaleService,
    private busyService: BusyService) { }

  ngOnInit() {
     this.setupDateRangePicker();
     this.buildForm();

    // Set up listener to disable form when application is bus
     this._isBusy = this.busyService.isBusy
      .subscribe({
        next: (isBusy: boolean) => {
          isBusy ? this.activityForm.disable() : this.activityForm.enable();
        }
      });
  }

  ngOnDestroy() {
    this._isBusy.unsubscribe();
  }

  /**
   * Locale needs to be set to swedish for daterangepicker
   */
  setupDateRangePicker(): void {
    defineLocale('sv', svLocale);
    this.localeService.use('sv');
  }

  /**
   *  Builds form for editing activity planned for a module item
   *
   * @param moduleItem module item
   */
  buildForm(): void {
    this.activityForm = this.formBuilder.group({
      visible: new FormControl(this.moduleItem.activity ? this.moduleItem.activity.visible : false),
      categories: new FormArray([]),
      startstop:
        this.moduleItem.activity.startdate && this.moduleItem.activity.stopdate
        ? new FormControl([ new Date(this.moduleItem.activity.startdate), new Date(this.moduleItem.activity.stopdate)])
        : null,
      estimated: new FormControl(this.moduleItem.activity ? this.moduleItem.activity.estimated : null),
    });

    this.categories.forEach(category => {
      const selected = this.moduleItem.activity.categories
        ? this.moduleItem.activity.categories.filter(x => x.id === category.id).length > 0
        : false;

      (this.activityForm.controls.categories as FormArray).push(new FormControl(selected));
    });
  }

  /**
   * Used after successful update to set form pristine and untouched
   */
  cleanForm(): void {
    this.activityForm.markAsPristine();
    this.activityForm.markAsUntouched();
  }

  /**
   * Updates start- and stopdate for activity
   */
  updateStartStop(): void {

    // only update if daterange is set
    if (!this.activityForm.value.startstop) {
      return;
    }

    // only update if changed
    if (!this.activityForm.get('startstop').dirty) {
      return;
    }

    const startdate = this.activityForm.value.startstop[0];
    const stopdate = this.activityForm.value.startstop[1];

    this.backendService.updateActivityStartStop(this.moduleItem, this.getDateString(startdate), this.getDateString(stopdate))
      .subscribe({
        next: () => {
          this.moduleItem.activity.startdate = startdate;
          this.moduleItem.activity.stopdate = stopdate;
          this.changed.emit(this.moduleItem);

          this.cleanForm();
        }
      });

  }

  /**
   * Removes start- and stopdate set for activity
   */
  removeStartStop(): void {
    this.backendService.updateActivityStartStop(this.moduleItem, null, null)
      .subscribe({
        next: () => {
          this.moduleItem.activity.startdate = null;
          this.moduleItem.activity.stopdate = null;
          this.changed.emit(this.moduleItem);

          this.activityForm.patchValue({ startstop: null });
        }
      });
  }

  /**
   * Updates if activity is visible in student view or not
   */
  updateVisible(): void {

    const visible = this.activityForm.value.visible;

    this.backendService.updateActivityVisible(this.moduleItem, visible)
      .subscribe({
        next: () => {
          this.moduleItem.activity.visible = visible;
          this.changed.emit(this.moduleItem);

          this.cleanForm();
        }
      });
  }

  /**
   * Updates estimated time for activity
   */
  updateEstimated(): void {

    // only update if changed
    if (!this.activityForm.get('estimated').dirty) {
      return;
    }

    const estimated = this.activityForm.value.estimated;

    this.backendService.updateActivityEstimated(this.moduleItem, estimated)
    .subscribe({
      next: () => {
        this.moduleItem.activity.estimated = estimated;
        this.changed.emit(this.moduleItem);

        this.cleanForm();
      }
    });
  }

  /**
   * Removes estimated time for activity
   */
  removeEstimated(): void {
    this.backendService.updateActivityEstimated(this.moduleItem, null)
      .subscribe({
        next: () => {
          this.moduleItem.activity.estimated = null;
          this.changed.emit(this.moduleItem);

          this.activityForm.patchValue({ estimated: null });
        }
      });
  }

  /**
   * Adds or removes category for activity
   * @param index index of category
   */
  setCategory(index: number): void {
    const selected = this.activityForm.value.categories[index];
    const category = this.categories[index];

    if (selected) {
      this.backendService.addCategory(this.moduleItem, category)
        .subscribe({
          next: () => {
            if (!this.moduleItem.activity.categories) {
              this.moduleItem.activity.categories = [];
            }
            this.moduleItem.activity.categories.push(category);
            this.changed.emit(this.moduleItem);

            this.cleanForm();
          }
        });
    } else {
      this.backendService.removeCategory(this.moduleItem, category)
        .subscribe({
          next: () => {
            this.moduleItem.activity.categories.splice(this.moduleItem.activity.categories.indexOf(category), 1);
            this.changed.emit(this.moduleItem);

            this.cleanForm();
          }
        });
    }
  }

  private getDateString(input: Date): string {
    const date = new Date(Date.UTC(input.getFullYear(), input.getMonth(), input.getDate()));
    return date.toISOString();
  }
}
